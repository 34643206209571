@tailwind base;
@tailwind components;
@tailwind utilities;
@font-face {
  font-family: 'Gilroy';
  font-weight: 700;
  font-style: normal;
  src: local('Gilroy-Bold'), url(./fonts/Gilroy-Bold.woff) format('woff');
}
@font-face {
  font-family: 'Gilroy';
  font-weight: 400;
  font-style: normal;
  src: local('Gilroy-Regular'), url(./fonts/Gilroy-Regular.woff) format('woff');
}

@font-face {
  font-family: 'Gilroy';
  font-weight: 500;
  font-style: normal;
  src: local('Gilroy-Medium'), url(./fonts/Gilroy-Medium.woff) format('woff');
}
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;700&display=swap');

body {
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  letter-spacing: 0.5px;
  color: #e3e3e3;
  border-radius: 10px;
  overflow: hidden;
}
.centering {
  display: flex;
  justify-content: center;
  align-items: center;
}
.glassed {
  background: rgba(196, 196, 196, 0.15);
  border: 1px solid rgba(210, 210, 210, 0.3);
  box-sizing: border-box;
  backdrop-filter: blur(9px);
  border-radius: 10px;
}

.glassed-blue,
.glassed.notify:hover {
  background: #536ae4;
  border: 1px solid rgba(210, 210, 210, 0.3);
  box-sizing: border-box;
  backdrop-filter: blur(9px);
  border-radius: 10px;
  transition: all;
  transition-duration: 0.5;
}

.rounded-100 {
  border-radius: 100%;
}
.add-plus-icon {
  position: relative;
  padding-right: 10px;
}
.add-plus-icon::after {
  content: '+';
  color: #8ca9f3;
  font-size: 14px;
  position: absolute;
  width: 10px;
  height: 20px;
  top: -2px;
  right: -2px;
}

.move-left {
  transform-origin: 50% 50%;
  transition: all;
  transition-duration: 1s;
  animation: alternate;
  animation: left 1s ease-in-out 0ms infinite;
}
.move-right {
  transform-origin: 50% 50%;
  transition: all;
  transition-duration: 1s;
  animation: alternate;
  animation: right 1s ease-in-out 0ms infinite;
}
@keyframes left {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(10px);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes right {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(-12px);
  }
  100% {
    transform: translateX(0);
  }
}

.calendar {
  width: 100% !important;
  min-height: auto !important;
  color: white !important;
  background-color: #262626 !important;
  font-size: 9px !important;
}
.Calendar__day.-ltr {
  min-height: auto !important;
}
.Calendar__day {
  color: #e3e3e3 !important;
}
.Calendar__sectionWrapper {
  min-height: 10.8rem !important;
}
abbr[title] {
  text-decoration: none !important;
  color: #555;
}
.Calendar__monthArrow {
  filter: invert(0.5);
}
.Calendar__day:not(.-blank):not(.-selectedStart):not(.-selectedEnd):not(.-selectedBetween):not(.-selected):hover {
  background-color: #131313 !important;
}
.Calendar__header {
  padding: 0 2.2rem !important;
  padding-bottom: 10px !important;
}
.brownDay {
  color: #bf7825 !important;
}
.greenDay {
  color: #1dea8f !important;
}
.grid_dashboard {
  display: grid;
  grid-template-columns: repeat(3, minmax(233px, 1fr));
  grid-template-rows: minmax(50px, 50px) minmax(308px, 50%) minmax(114px, 1fr);
}
.gridProfiles {
  padding-right: 10px;
  grid-template-columns: repeat(auto-fill, minmax(223px, 1fr));
  grid-auto-rows: min-content;
}

.gridMonitors {
  grid-template-columns: repeat(auto-fill, minmax(232px, 1fr));
  grid-auto-rows: min-content;
  padding-right: 10px;
}
.gridCaptcha {
  grid-template-columns: repeat(auto-fill, minmax(223px, 1fr));
  grid-auto-rows: min-content;
  padding-right: 10px;
}
.tasksTable {
  border-collapse: separate;
  border-spacing: 0px 10px;
  text-align: start;
  position: relative;
}
.scrl::-webkit-scrollbar {
  width: 3px;
}
.scrl::-webkit-scrollbar-thumb {
  background: linear-gradient(100.62deg, #526ae3 15.79%, #8ca9f3 84.21%);
}
th {
  position: sticky;
  top: 0;
  background: #262626;
}
td,
th {
  text-align: start;
}
th {
  border-bottom: 3px solid #1b1b1b;
}
th:last-child,
td:last-child {
  padding-right: 10px;
}
th:first-child,
td:first-child {
  padding-left: 18px;
}
tr {
  border-radius: 5px;
}
td:first-child,
th:first-child {
  border-radius: 5px 0 0 5px;
}
td:last-child,
th:last-child {
  border-radius: 0 5px 5px 0;
}
button:focus {
  outline: 0;
}
.bubble {
  background: linear-gradient(
    151.76deg,
    rgba(255, 255, 255, 0.77) 11.95%,
    rgba(131, 149, 236, 0.67) 69.71%,
    #5d75e6 87.39%
  );
  transition: all;
  transition-duration: 1s;
  /* disable bubble animation HERE */
  animation: bubbling 5s ease-in-out infinite alternate;
}

@keyframes bubbling {
  0% {
    transform: scale(1) translateX(0) translateY(0) translateZ(0);
  }
  50% {
    transform: scale(1.05) translateX(5px) translateY(-5px) translateZ(-4px);
  }
  100% {
    transform: scale(1) translateX(0) translateY(0) translateZ(0);
  }
}
